import styled from 'styled-components';
import { SkeletonBlock } from './SkeletonBlock';

const Header = styled.div`
  height: 42px;
`;

const Row = styled(SkeletonBlock)`
  height: 64px;
  &:nth-child(even) {
    background-color: #fff;
  }
`;

interface SkeletonTableProps {
  numRows?: number;
  header?: boolean;
}

export const SkeletonTable = ({ header = true, numRows = 5, ...otherProps }: SkeletonTableProps) => {
  const rows = [];
  for (let i = 0; i < numRows; i++) {
    rows.push(<Row key={i} />);
  }
  return (
    <div {...otherProps}>
      {header && <Header />}
      <div>{rows}</div>
    </div>
  );
};
