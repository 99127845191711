import styled from 'styled-components';

import { SkeletonBlock } from './SkeletonBlock';
import { FieldWrapper } from '../Form/FieldWrapper/FieldWrapper';

export const SkeletonTextInput = styled(SkeletonBlock)`
  height: 63px;
`;

export const SkeletonTextField = (props: any) => (
  <FieldWrapper {...props}>
    <SkeletonTextInput />
  </FieldWrapper>
);
