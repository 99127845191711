import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import { Colors } from '../../../styles/colors';

const Container = styled.div`
  svg {
    width: 100%;
    height: auto;
  }
`;

export const Skeleton = ({ children, width = 300, height = 180 }: SkeletonProps) => {
  return (
    <Container>
      <ContentLoader
        speed={3}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={Colors.ContrastLightest}
        foregroundColor={Colors.Primary}
        backgroundOpacity={0.3}
        foregroundOpacity={0.3}
      >
        {children}
      </ContentLoader>
    </Container>
  );
};

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: number;
  height?: number;
}
