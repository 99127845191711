import React from 'react';
import styled from 'styled-components';
import { SkeletonBlock, SkeletonBlockStyles } from './SkeletonBlock';
import { media } from '../../../styles/media';
import { H2 } from '../Typography/Headings';

export const SkeletonText = styled(SkeletonBlock)`
  height: 18px;
  margin-bottom: 4px;
  margin-top: 3px;
`;

interface SkeletonCopyProps {
  numLines?: number;
  lastLineWidth?: string;
}

const CopyWrapper = styled.div`
  ${SkeletonText} {
    &:last-of-type {
      margin-bottom: 0;
    }
    &:nth-child(2n) {
      animation-delay: 0.5s;
    }
    &:nth-child(3n) {
      animation-delay: 1s;
    }
    &:nth-child(4n) {
      animation-delay: 1.5s;
    }
  }
`;

export const SkeletonCopy = ({ numLines = 3, lastLineWidth = '33%' }: SkeletonCopyProps) => {
  const lines: any[] = [];
  for (let i = 0; i < numLines; i++) {
    const style = {
      width: i === numLines - 1 ? lastLineWidth : '100%',
    };
    lines.push(<SkeletonText key={i} style={style} />);
  }

  return <CopyWrapper>{lines}</CopyWrapper>;
};

export const SkeletonH2 = styled(H2)`
  ${SkeletonBlockStyles({})}
  color: transparent;
  display: inline-block;
  &:after {
    opacity: 0;
  }
`;

export const SkeletonH3 = styled(SkeletonBlock)`
  height: 32px;
  ${media.phone`
    height: 30px;
  `}
`;
